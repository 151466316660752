import React from 'react';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notifyTypes = {
    "success" : toast.success,
    "error" : toast.error,
    "warn" : toast.warn,
    "info" : toast.info
}

const Notify = (type, message) => {
    if(notifyTypes[type]){
        return notifyTypes[type](message)
    }else{
        return null
    }
}

const NotifyDiv = () => {
 return (
    <ToastContainer
    position="top-right"
    autoClose={5000}
    limit={3}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
    theme="light"
    transition={Slide}
    />
 )
}

export { NotifyDiv, Notify };