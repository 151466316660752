import { useState, useContext } from "react";
import {Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { AlreadyLogged, PrivateRoute } from "./services/pageAccess.jsx";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./services/scrollToTop.jsx";
import UpdateCartOnPageChange from "./services/UpdateCartOnPageChange.jsx";
import ValidateIsManut from "./services/validateIsManut.jsx";
import { ManutContext } from "./contexts/manutContext.jsx";
import Cookies from 'js-cookie';
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const IS_ONLINE = 1;

import Header from "./components/Header";
import Footer from "./components/Footer";

import Homepage from "./pages/homepage";
import Notfound from "./pages/notfound/notfound.jsx";
import Pagina from "./pages/pagina/pagina.jsx";
import TPagina from "./pages/tpagina/tpagina.jsx";
import Contatos from "./pages/contatos/contatos.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Login from "./pages/login/login.jsx";
import Carrinho from "./pages/carrinho/carrinho.jsx";
import Registo from "./pages/registo/registo.jsx";
import Obrigado from "./pages/obrigado/obrigado.jsx";
import Recuperar from "./pages/recuperar/recuperar.jsx";
import Btwob from "./pages/btwob/btwob.jsx";
import Finalizar from "./pages/finalizar/finalizar.jsx";
import Perfil from "./pages/perfil/perfil.jsx";
import Encomendas from "./pages/encomendas/encomendas.jsx";
import HistoricoEncomendas from "./pages/historico_encomendas/historico_encomendas.jsx";
import HistoricoFaturas from "./pages/historico_faturas/historico_faturas.jsx";
import ContaCorrente from "./pages/cc/cc.jsx";
import ManutencaoPage from "./pages/manutencao/manutencao.jsx";

const Content = () => {
    const {t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const[searchParams] = useSearchParams();
    const codigo = searchParams.get("codigo");
    const {manutAuth} = useContext(ManutContext);

    if(codigo){
        let expiresIn = new Date(new Date().getTime() + 60 * 60 * 1000); // primeiro valor é o dos minutos = 60 minutos 
        Cookies.set("codigo", codigo, { expires : expiresIn})
    }

    return (
        <main>
            <ValidateIsManut>
                {manutAuth === true &&(
                    <>
                        <Header></Header>
                        <UpdateCartOnPageChange>           
                            <ScrollToTop>
                                <Routes>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT :'/'}
                                        element={<Navigate to={`${currentLanguage}/home`}/>}>                            
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/home' : currentLanguage+'/home'}
                                        element={<Homepage/>}>
                                    </Route>                    
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/empresa' : currentLanguage+'/empresa'}
                                        element={<Pagina/>}>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/pages/:pagina' : currentLanguage+'/pages/:pagina'} //politica-privacidade
                                        element={<TPagina/>}>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/registo' : currentLanguage+'/registo'}
                                        element={
                                            <AlreadyLogged>
                                                <Registo/>
                                            </AlreadyLogged>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/contatos' : currentLanguage+'/contatos'}
                                        element={<Contatos/>}>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/dashboard' : currentLanguage+'/dashboard'}
                                        element={
                                            <PrivateRoute>
                                                <Dashboard/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/perfil' : currentLanguage+'/perfil'}
                                        element={
                                            <PrivateRoute>
                                                <Perfil/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/login' : currentLanguage+'/login'}
                                        element={
                                            <AlreadyLogged>
                                                <Login/>
                                            </AlreadyLogged>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/carrinho' : currentLanguage+'/carrinho'}
                                        element={
                                            <PrivateRoute>
                                                <Carrinho/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/comprar' : currentLanguage+'/comprar' }
                                        element={
                                            <PrivateRoute>
                                                <Finalizar/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/encomendas/' : currentLanguage+'/encomendas' }
                                        element={
                                            <PrivateRoute>
                                                <Encomendas/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/encomendas/:nencomenda' : currentLanguage+'/encomendas/:nencomenda' }
                                        element={
                                            <PrivateRoute>
                                                <Encomendas/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/encomendas/historico' : currentLanguage+'/encomendas/historico' }
                                        element={
                                            <PrivateRoute>
                                                <HistoricoEncomendas/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/faturas/historico' : currentLanguage+'/faturas/historico' }
                                        element={
                                            <PrivateRoute>
                                                <HistoricoFaturas/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/obrigado/:pagina' : currentLanguage+'/obrigado/:pagina'}
                                        element={<Obrigado data={"&nbsp;"}/>}>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/obrigado/encomenda/:pagina/:nencomenda/:total' : currentLanguage+'/obrigado/encomenda/:pagina/:nencomenda/:total'}
                                        element={<Obrigado data={"&nbsp;"}/>}>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/recuperar' : currentLanguage+'/recuperar'}
                                        element={
                                            <AlreadyLogged>
                                                <Recuperar/>
                                            </AlreadyLogged>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/loja' : currentLanguage+'/loja'}
                                        element={
                                            <PrivateRoute>
                                                <Btwob/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/contacorrente' : currentLanguage+'/contacorrente'}
                                        element={
                                            <PrivateRoute>
                                                <ContaCorrente/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={IS_ONLINE === 0 ? VITE_PUBLIC_ROOT+'/'+currentLanguage+'/contacorrente/:menu' : currentLanguage+'/contacorrente/:menu'}
                                        element={
                                            <PrivateRoute>
                                                <ContaCorrente/>
                                            </PrivateRoute>
                                        }>
                                    </Route>
                                    <Route path={'/*'} element={<Notfound />} />
                                </Routes>
                                <Footer></Footer>
                                <CookieConsent
                                    location="bottom"
                                    buttonText={t(currentLanguage+"_cookies-entendi")}
                                    cookieName="zoomtyrecookie"
                                    style={{ background: "#2B373B" }}
                                    buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
                                    expires={1}
                                    >
                                    <div id="cookiethissite" className="lign-center">
                                        <h3 className="title">{t(currentLanguage+"_cookies-title")}</h3>
                                        <p className="message">
                                            {t(currentLanguage+"_cookies-message")}
                                            <a href={"http://ec.europa.eu/info/cookies_"+currentLanguage} target="_blank">{t(currentLanguage+"_cookies-here")}</a>.</p></div>
                                </CookieConsent>
                            </ScrollToTop>
                        </UpdateCartOnPageChange>
                    </>
                )}
                {manutAuth === false &&(
                    <Routes>
                        <Route path={'/*'}
                            element={<Navigate to={`/`}/>}>                            
                        </Route>
                        <Route path={'/'}
                            element={<ManutencaoPage/>}>
                        </Route>
                        
                    </Routes>   
                )}
            </ValidateIsManut>    
        </main>
    )
}

export default Content;