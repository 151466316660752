import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL
export async function submitContact(name, email, phone, message, alias, language, token){
    try {
        const response = await axios.post(apiUrl+"contact/", {
            name : name,
            phone : phone,
            email :  email,
            message : message,
            alias: alias,
            language: language,
            tokencaptcha: token
        })
        return true
    } catch (error) {
        console.log(error.response.data)
        throw error
        // console.log(error.response.data.message)
    }
}

export async function getPageContactoAPI(language){
    try {
        const response = await axios.get(apiUrl+"page/contactos/"+language+"/")
        return response.data.page
    } catch (error) {
        console.log(error.response.data.message)
    }
}
