import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from "../../components/MetaPages.jsx";
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
import { getPageAPI } from "../../data/tpagina.jsx";
import NavAreaPrivada from "../../components/NavAreaPrivada.jsx";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext.jsx";

import { getAllFaturasPHC,getLinhasFaturasPHC, sendEmailFaturaPHC } from "../../data/apiPHC.jsx";

import { formatarData } from "../../services/utils.jsx";

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import ReactGA from 'react-ga4';

import './historico_faturas.scss'

import LoadingPage from "../../components/LoadingPage.jsx";
import Spinner from "../../components/Spinner.jsx";

const HistoricoFaturas = () => {
    const [loading, setLoading] = useState(false);

    const { userData } = useContext(AuthContext)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;

    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [faturas, setFaturas] = useState([])
    const [casasDecimais, setCasasDecimais] = useState(2)
    // const [expandedRow, setExpandedRow] = useState({});
    const [dataRow, setDataRow] = useState({})

    const [rowExpanded, setRowExpanded] = useState({})
    const [loadingRow, setLoadingRow] = useState({})

    const [sendEmailSuccess, setSendEmailSuccess] = useState({})

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("faturas", "faturas", currentLanguage)
        setPage(response)
    }

    async function getFaturas(){
        const response = await getAllFaturasPHC(userData.no)
        setFaturas(response)
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    const getDataFatura = async (stamp) => {
        const response = await getLinhasFaturasPHC(stamp)
        setDataRow({...dataRow, [stamp]:response})
    }

    const handleRowClick = (stamp) => {
        if(!dataRow[stamp]){
            getDataFatura(stamp)
        }
        if(!rowExpanded[stamp]){
            setRowExpanded({...rowExpanded, [stamp]: true})
        }else{
            setRowExpanded({...rowExpanded, [stamp]: false})
        }
    };

    const handleSendFaturaEmail = async (nfatura, datafatura, totalfatura, stamp) => {
        try {
            setLoadingRow({...loadingRow, [stamp]: true})
            await sendEmailFaturaPHC(userData.name, userData.email, userData.no, nfatura, datafatura, totalfatura, 'pedido-fatura', currentLanguage)
            setLoadingRow({...loadingRow, [stamp]: false})
            setSendEmailSuccess({...sendEmailSuccess, [stamp]:true})
            setTimeout(() => {
                setSendEmailSuccess({...sendEmailSuccess, [stamp]:false})
            }, 2000);
        } catch (error) {
            setLoadingRow({...loadingRow, [stamp]: false})
        } 
    }

    useEffect(() =>{
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getFaturas(),
                        getCasasDecimais()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>   
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="reservada">
                <div className="container-fluid">
                    <div className="row">
                        <NavAreaPrivada></NavAreaPrivada>
                        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                            {t(currentLanguage+'_area-privada')}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t(currentLanguage+'_historico-faturas-title')}
                                    </li>
                                </ol>
                            </nav>
                            <h1 class="h2">
                                {t(currentLanguage+'_historico-faturas-title')}
                            </h1>
                            <p>
                                {t(currentLanguage+'_historico-faturas-description')}
                            </p>
                            
                            <div class="row">
                                <div class="col-12 col-xl-12 mb-4 mb-lg-0">
                                    {loading ? (
                                        <LoadingPage/>
                                    ): (
                                        <div class="card">
                                            <h5 class="card-header">
                                            {t(currentLanguage+'_historico-faturas-lista-faturas')}
                                            </h5>
                                            <div class="card-body">
                                                {faturas.length > 0 ? (
                                                    <div class="table-responsive">
                                                        <table class="table accordion table-striped table-hover tabelamobile">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_numero-fatura')}
                                                                    </th>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_data-fatura')}
                                                                    </th>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_total-faturas')}
                                                                    </th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {faturas.map((fatura) =>{
                                                                    return(
                                                                        <>
                                                                            <tr key={fatura.ftstamp} id={`linhaFatura${fatura.ftstamp}`}>
                                                                                <th scope="row" data-bs-toggle="collapse" data-bs-target={"#fatura"+fatura.fno} onClick={() => handleRowClick(fatura.ftstamp)} role="button">{fatura.ftano+"A"+fatura.ndoc+"/"+fatura.fno} <i className={`bi ${rowExpanded[fatura.ftstamp] ? "bi-chevron-down" : "bi-chevron-right"}`}></i></th>
                                                                                <td>
                                                                                    {formatarData(fatura.fdata)}
                                                                                </td>
                                                                                <td>
                                                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(fatura.etotal)}
                                                                                </td>
                                                                                <td className="d-flex justify-content-end align-items-center">
                                                                                    <button className={`btn ${sendEmailSuccess[fatura.ftstamp] ? "btn-success" : "btn-secondary"}`} onClick={() => handleSendFaturaEmail((fatura.ftano+"A"+fatura.ndoc+"/"+fatura.fno),formatarData(fatura.fdata),fatura.etotal, fatura.ftstamp)}  disabled={sendEmailSuccess[fatura.ftstamp] || loadingRow[fatura.ftstamp]}>
                                                                                        {sendEmailSuccess[fatura.ftstamp] ? (
                                                                                                <i className={"bi bi-check-circle-fill"}></i>
                                                                                        ):(
                                                                                            <>
                                                                                                {loadingRow[fatura.ftstamp] ? (
                                                                                                    <Spinner/>
                                                                                                ):(
                                                                                                    <span data-alttext="\2709"><span>{t(currentLanguage+"_btn-pedir-fatura-email")}</span></span>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                        
                                                                                    </button>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr class="collapse accordion-collapse" id={"fatura"+fatura.fno} data-bs-parent=".table">
                                                                                <td colspan="6">
                                                                                    <div class="tabresponsive-mobile">
                                                                                        <table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100% table-light table-hover">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_referencia-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_designacao-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_rotulo-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_qtt-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_preco-unitario-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_desconto-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col">
                                                                                                        {t(currentLanguage+'_total-faturas')}
                                                                                                    </th>
                                                                                                    <th scope="col" className="text-end">
                                                                                                        {t(currentLanguage+'_iva-faturas')}
                                                                                                    </th>
                                                                                                    {/* <th scope="col" class="text-end">
                                                                                                        {t(currentLanguage+'_total-encomenda')}
                                                                                                    </th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {dataRow[fatura.ftstamp] &&(
                                                                                                    <>
                                                                                                        {dataRow[fatura.ftstamp].map((lfatura) =>{
                                                                                                            return (
                                                                                                                <tr>
                                                                                                                    <th scope="row" class="small ">{lfatura.ref}</th>
                                                                                                                    <td data-label={t(currentLanguage+'_designacao-faturas')} class="small ">{lfatura.design}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_rotulo-faturas')} class="small ">{t(currentLanguage+'_rotulo-faturas')}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_qtt-faturas')} class="small ">{lfatura.qtt || ""}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_preco-unitario-faturas')} class="small ">{lfatura.epv > 0  ? new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(lfatura.epv) : ""}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_desconto-faturas')} className="small">{lfatura.desconto > 0 ? lfatura.desconto+" %" : ""}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_total-faturas')} class="small">{lfatura.etiliquido > 0 ? new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(lfatura.etiliquido) : ""}</td>
                                                                                                                    <td data-label={t(currentLanguage+'_iva-faturas')} class="small text-end">{lfatura.iva} %</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })}
                                                                                                        <tr>
                                                                                                            <td colspan="7" class="text-end small">
                                                                                                                <strong>
                                                                                                                    {t(currentLanguage+'_total-iliquido-faturas')}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                            <td class="text-end small">
                                                                                                                <strong>
                                                                                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(fatura.ettiliq)}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colspan="7" class="text-end small">
                                                                                                                <strong>
                                                                                                                    {t(currentLanguage+'_iva-faturas')}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                            <td class="text-end small">
                                                                                                                <strong>
                                                                                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(fatura.ettiva)}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td colspan="7" class="text-end small">
                                                                                                                <strong>
                                                                                                                    {t(currentLanguage+'_total-faturas')}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                            <td class="text-end small">
                                                                                                                <strong>
                                                                                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(fatura.etotal)}
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ):(
                                                    <div class="alert alert-primary" role="alert">
                                                        <strong>
                                                            {t((currentLanguage+"_nao-existem-faturas"))}
                                                        </strong>
                                                    </div>
                                                )}
                                            </div>
                                        </div>   
                                    )}
                                </div>                        
                            </div>                    
                        </main>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HistoricoFaturas