import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { getPageAPI } from "../../data/tpagina";
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia";
import { getParameterByAliasAPI } from "../../data/parameter";
import MetaPages from "../../components/MetaPages";
import { Base64 } from 'js-base64';
import { submitRegisterForm } from "../../data/register";
import Captcha from "../../components/Captcha";

import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
import './registo.scss'

const Registo = () => {
    const reCaptchaRef = useRef()
    const [recaptchaType, setRecaptchaType] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [dataForm, setDataForm] = useState({ nomeEmpresa: "", nif: "", morada:"", localidade: "", cpostal: "", distrito: "", pais:"", email: "", telefone: "", nomeContacto: "", telefoneContacto: "", comentario: ""})
    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
    const { t, i18n: {language} } = useTranslation();

    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('')

    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")

    const [currentLanguage] = useState(language);

    const [page, setPage] = useState("")
    const [ image, setImage] = useState("")

    const [errorCaptcha, setErrorCaptcha] = useState(false)

    const [captchaValid, setCaptchaValid] = useState(false)

    async function getRecaptchaType(){
        const response = await getParameterByAliasAPI("recaptchatype")
        setRecaptchaType(response.int)
    }

    function handleValidateCaptcha(data){
        setCaptchaValid(data)
    }

    function handleSetErrorCaptcha(data){
        setErrorCaptcha(data)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("registo",  currentLanguage)
        setImage(response)
    }

    async function getPage(){
        const response = await getPageAPI("registo", "registo",currentLanguage)
        setPage(response)
    }

    async function getRecaptchaSiteKey(){
        const response = await getParameterByAliasAPI("recaptchasitekey")
        setRecaptchaSiteKey(response.varchar)
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getImage(),
                        getPage(),
                        getNomeCliente(),
                        getRecaptchaType(),
                        getRecaptchaSiteKey()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    async function handleSubmitFormRegister(e){
        e.preventDefault()
        if(!loadingForm){
            if((recaptchaType === 0 && captchaValid) || recaptchaType === 1){
                try {
                    setLoadingForm(true)
                    let token
                    if(recaptchaType === 1){
                        token = await reCaptchaRef.current.executeAsync();
                        reCaptchaRef.current.reset();
                    }
                    await submitRegisterForm(dataForm.nomeEmpresa, dataForm.nif, dataForm.morada, dataForm.localidade, dataForm.cpostal, dataForm.distrito, dataForm.pais, dataForm.email, dataForm.telefone, dataForm.nomeContacto, dataForm.telefoneContacto, dataForm.comentario, "registo", currentLanguage, token)
                    setDataForm({ nomeEmpresa: "", nif: "", morada:"", localidade: "", cpostal: "", distrito: "", pais:"", email: "", telefone: "", nomeContacto: "", telefoneContacto: "", comentario: ""})
                    setLoadingForm(false);
                    window.location.replace(VITE_PUBLIC_ROOT+'/'+currentLanguage+'/obrigado/obrigado-registo');
                } catch (error) {
                    setLoadingForm(false)
                }
            }else{
                setErrorCaptcha(true)
            }
        }
    }

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <> 
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="top-imagem">
                <div className="container-fluid">
                    {image &&(
                        <div className="row justify-content-center img-top-content" style={{backgroundImage : 'url('+BaseImgPath+image.path+')'}}>
                            <div className="col-12 this-center-all">
                                <div className="wrapper-content ">
                                    {page && (
                                        <h2 className="display-5-b text-white" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></h2>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section>
                <div className="form_geral">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="form_geral_container">
                                    <div className="form_geral_title s-text">
                                    {t(currentLanguage+'_registo-text-1')}<br/>
                                        <h6>{t(currentLanguage+'_registo-text-2')}<br/>
                                        {t(currentLanguage+'_registo-text-3')}<br/></h6>
                                    </div>
                                    
                                    <form id="form_geral_form" onSubmit={(e) => handleSubmitFormRegister(e)}>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input onChange={(e) => setDataForm({...dataForm, nomeEmpresa: e.target.value})} value={dataForm.nomeEmpresa} type="text" className=" input_field" placeholder={t(currentLanguage+'_nome-empresa')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input onChange={(e) => setDataForm({...dataForm, nif: e.target.value})} value={dataForm.nif} type="text" className=" input_field threeinput" placeholder={t(currentLanguage+'_nif')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input onChange={(e) => setDataForm({...dataForm, morada: e.target.value})} value={dataForm.morada} type="text" className=" input_field" placeholder={t(currentLanguage+'_morada')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between">
                                            <input type="text" onChange={(e) => setDataForm({...dataForm, localidade: e.target.value})} value={dataForm.localidade} className=" input_field halfinput" placeholder={t(currentLanguage+'_localidade')} required="required" data-error="Campo obrigatório."/>
                                            <input type="text" onChange={(e) => setDataForm({...dataForm, cpostal: e.target.value})} value={dataForm.cpostal} className=" input_field halfinput" placeholder={t(currentLanguage+'_codigo-postal')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between">
                                            <input onChange={(e) => setDataForm({...dataForm, distrito: e.target.value})} value={dataForm.distrito} type="text" className=" input_field halfinput" placeholder={t(currentLanguage+'_distrito')} required="required" data-error="Campo obrigatório."/>
                                            <input onChange={(e) => setDataForm({...dataForm, pais: e.target.value})} value={dataForm.pais} type="text" className=" input_field halfinput" placeholder={t(currentLanguage+'_pais')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between">
                                            <input onChange={(e) => setDataForm({...dataForm, email: e.target.value})} value={dataForm.email} type="email" className=" input_field halfinput" placeholder={t(currentLanguage+'_email')} required="required" data-error="Campo obrigatório."/>
                                            <input onChange={(e) => setDataForm({...dataForm, telefone: e.target.value})} value={dataForm.telefone} type="tel" className=" input_field halfinput" placeholder={t(currentLanguage+'_telefone')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input onChange={(e) => setDataForm({...dataForm, nomeContacto: e.target.value})} value={dataForm.nomeContacto} type="text" className=" input_field" placeholder={t(currentLanguage+'_nome-contacto')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input onChange={(e) => setDataForm({...dataForm, telefoneContacto: e.target.value})} value={dataForm.telefoneContacto} type="tel" className=" input_field threeinput" placeholder={t(currentLanguage+'_telefone-contacto')} required="required" data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <textarea onChange={(e) => setDataForm({...dataForm, comentario: e.target.value})} value={dataForm.comentario} id="reg_form_message" className="input_field form_message" name="message" rows="6" placeholder={t(currentLanguage+'_comentario')}></textarea>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input type="checkbox" className="form-check-input" id="politica" required="required"/>
                                            <label className="form-check-label" for="politica">
                                                <span className="text-black">
                                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/politica-privacidade"}>
                                                        {t(currentLanguage+'_aceito-politica-privacidade')}
                                                    </Link>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-text-content">
                                            <span className="text-black">{t(currentLanguage+'_todos-os-campos-sao-obrigatorios')}</span>
                                        </div>
                                        {recaptchaType === 0 &&(
                                            <>
                                                {!captchaValid &&(
                                                    <Captcha handleValidateCaptcha={handleValidateCaptcha} errorCaptcha={errorCaptcha} handleSetErrorCaptcha={handleSetErrorCaptcha}/>
                                                )}
                                                {captchaValid &&(
                                                    <span className="text-success">
                                                        {t(currentLanguage+"_capcha-success-message")}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        {recaptchaType === 1 && recaptchaSiteKey &&(
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                sitekey={recaptchaSiteKey}
                                                badge="bottomright"
                                                size="invisible"
                                            />
                                        )}
                                        <div className="form_geral_button text-center">
                                            <button type="submit" className="btn btn-secondary btn-black form_geral_submit_button half-w">
                                                {loadingForm ? (
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">{t(currentLanguage+'_aguarde')}</span>
                                                    </div> 
                                                ):(
                                                    <span>{t(currentLanguage+'_enviar-pedido-registo')}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        
        </>
    )
}

export default Registo