import React,{useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import Spinner from './Spinner'
import { updatePasswordPHC } from '../data/apiPHC'

const ModalResetPassword = () => {
    const {userData} = useContext(AuthContext)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ password: "", newPassword: "", no: userData.no, user: userData.username})

    const handleResetData = () => {
        setData({ password: "", newPassword: "", no: userData.no, user: userData.username})
    }
    
    const handleSubmitResetPassword = async (e) => {
        e.preventDefault()
        if(!loading){
            setLoading(true)
            try {
                await updatePasswordPHC(data)
                handleResetData()
                hideModal()
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const hideModal = () => {
        document.getElementById('closeModalAlterarPassword').click();
    }

    return (
        <div className="modal fade" id="modalAlterarPassword" tabindex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          {t(currentLanguage+"_perfil-alterar-palavra-passe")}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-geral">
                            <form id="form_change_password" onSubmit={(e) => handleSubmitResetPassword(e)}>
                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                    <div className="w-100 p-0">
                                        <div className="row row-gap-3">
                                            <div className="col col-12">
                                                <label htmlFor='currentPassword' className="w-100 form-label">
                                                    {t(currentLanguage+"_palavra-passe-atual")}
                                                </label>
                                                <input onChange={(e) => setData({...data, password: e.target.value})} value={data.password} id="currentPassword" type="password" className="form-control" placeholder={t(currentLanguage+"_palavra-passe-atual")} required data-error="Campo obrigatório."/>
                                            </div>
                                            <div className="col col-12">
                                                <label htmlFor='newPassword' className="w-100 form-label">
                                                    {t(currentLanguage+"_palavra-passe-nova")}
                                                </label>
                                                <input onChange={(e) => setData({...data, newPassword: e.target.value})} value={data.newPassword} id="newPassword" type="password" className="form-control" placeholder={t(currentLanguage+"_palavra-passe-nova")} required data-error="Campo obrigatório."/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" id='closeModalAlterarPassword' onClick={() => handleResetData()} className="btn btn-light p-2" data-bs-dismiss="modal">
                          {t(currentLanguage+'_cancelar')}
                        </button>
                        <button type="submit" form="form_change_password" className="btn btn-secondary p-2">
                            {!loading ? (
                                t(currentLanguage+"_perfil-alterar-palavra-passe")
                            ):(
                                <Spinner/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalResetPassword