import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function getPageAPI(slug,category, language){
    try {
        // const response = await axios.get(apiUrl+"page/"+slug+"/"+language+"/")
        const response = await axios.get(apiUrl+"page/"+slug+"/"+category+"/"+language+"/")
        return response.data.page
    } catch (error) {
        console.log(error.response.data.message)
    }
}

