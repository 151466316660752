import { useEffect, useState, useContext} from "react";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { MdImageSearch } from "react-icons/md";
import { TiWeatherSunny } from "react-icons/ti";
import { TiWeatherSnow } from "react-icons/ti";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { getAlternativasDataPHC } from "../../data/apiPHC";
import Spinner from "../../components/Spinner";
import './btwob.scss'
import { ProdutoDestaqueAlternativa } from "./modimagem";
import { addCartItemAPI } from "../../data/cart";
import { CartContext } from "../../contexts/cartContext";
import { AuthContext } from "../../contexts/authContext";

const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

const Alternativas = (props) => {
    const { updateCart, cartItems  } = useContext(CartContext);
    const {userData} = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState({}); 
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [artigos, setArtigos] = useState(props.alternativas)
    const [loadingAddCart, setLoadingAddCart] = useState(false)
    const [loadingAddCartItem, setLoadingAddCartItem] = useState({})
    const [dataProduto, setDataProduto] = useState("")
    const [referencia, setRefencia] = useState(props.referencia)

    const [successAddCartItem, setSuccessAddCartItem] = useState({})
    const [noStockAddCartItem, setNoStockAddCartItem] = useState({})

    // const getArtigos = async () => {
    //     if(referencia){
    //         const response = await getAlternativasDataPHC(referencia)
    //         return setArtigos(response)
    //     }
    //     return null
    // }

    const handleQty = (e, stock) => {
        e.preventDefault();
        setNumber({...number,[e.target.name]: e.target.value});
        // if (e.target.value <= stock && e.target.value > 0){
            
        // }
    };

    const handleSubmit = async (e,referencia,ststamp) => {
        e.preventDefault();
        if(!loadingAddCart){
            if(userData.no && userData.clstamp ){
                try {
                    setLoadingAddCart(true)
                    setLoadingAddCartItem({ [referencia]: true})
                    const qtd = number[referencia] || 1
                    const data = { no : userData.no, clstamp : userData.clstamp, products: [{ref: referencia, ststamp: ststamp, quantidade: qtd}]}
                    await addCartItemAPI(data,t,currentLanguage)
                    setLoadingAddCart(false)
                    setLoadingAddCartItem({ [referencia]: false})
                    updateCart()
                    setSuccessAddCartItem((prevState) => ({ ...prevState, [referencia]: true }));
                    setTimeout(() => {
                        setSuccessAddCartItem((prevState) => ({ ...prevState, [referencia]: false }));
                    }, 2000);
                } catch (error) {
                    if(error === "nostock"){
                        setNumber(prevNumber => ({ ...prevNumber, [referencia]: 1 }));
                        setNoStockAddCartItem((prevState) => ({ ...prevState, [referencia]: true }));
                        setTimeout(() => {
                            setNoStockAddCartItem((prevState) => ({ ...prevState, [referencia]: false }));
                        }, 2000);
                    }
                    setLoadingAddCart(false)
                    setLoadingAddCartItem({ [referencia]: false})
                }
            }
        }
    };

    const columns = [
        {
            id: 'marca',
            name: t(currentLanguage+'_marca-loja'),
            sortable: true,
            grow: 0,
            selector: row => row.marca,
            cell: (row, index) => {
                const ismarca = row.marca;
                const imageExists = props.imagesBrands.find(brandImage => (brandImage.alias).toUpperCase() === (ismarca).toUpperCase())
                if(imageExists && ismarca){
                    return(
                        <img className="imagemarca" src={BaseImgPath+imageExists.path} alt={ismarca} />
                    )
                }else{
                    return(
                        <img className="imagemarca" src={BaseImgPath+"/images/zoomtyre.png"} alt="zoomtyre logo" />
                    )
                }
            },
            // hide: 'sm', 
        },
        {
            id: 'medida',
            name: t(currentLanguage+'_medida-loja'),
            grow: 1,
            selector: row => <span>{row.medidaex}</span>,
            left:true,
        },
        {
            id: 'ic',
            name: t(currentLanguage+'_ic-loja'),
            width: '5%',
            grow: 0,
            selector: row => row.ic,
            center: true,
            // hide: 'sm',
        },
        {
            id: 'iv',
            name: t(currentLanguage+'_iv-loja'),
            width: '5%',
            grow: 0,
            selector: row => row.iv,
            center: true,
            // hide: 'sm',
        },
        {
            id: 'modelo',
            name: t(currentLanguage+'_modelo-loja'),
            selector: row => row.modelo,
            left:true,
            // hide: 'md',
        },
        {
            id: 'epoca',
            name: t(currentLanguage+'_epoca-loja'),
            maxWidth: '5%',
            grow: 0,
            center: true,
            cell: (row, index) => {
                const isepoca = row.epoca;
                return(
                    isepoca == 'Verão'
                    ? <span className="epoca"><TiWeatherSunny/></span>
                    :isepoca == 'Inverno'
                    ? <span className="epoca"><TiWeatherSnow/></span>
                    :isepoca == 'AllSeason'
                    ? <span className="epoca"><TiWeatherPartlySunny/></span>
                    : <span className="epoca"><TiWeatherSunny/></span>
                )
            },
            hide: 'sm',
        },
        {
            id: 'stock',
            name: t(currentLanguage+'_stock-loja'),
            grow: 0,
            // width: '7%',
            sortable: true,
            selector: row => row.stock,
            center: true,
        },
        {
            id: 'preco',
            name: t(currentLanguage+'_preco-loja'),
            grow: 0,
            sortable: true,
            cell: row => new Intl.NumberFormat('pt-PT', { maximumFractionDigits: props.casasDecimais ,minimumFractionDigits: props.casasDecimais || 2, style: 'currency', currency: 'EUR' }).format(row.preco), 
            center: true,
            selector: row => row.preco,
        },        
        {
            id: 'rotulagem',
            name: t(currentLanguage+'_rotulagem-loja'),
            width: '20%',
            grow: 1,
            cell: row => (
                <div className="rotulagem">
                    <ul>
                        <li><img className="imagerotulagem" src={BaseImgPath+"/images/efienergbk.png"} alt="eficiencia energetica" />{row.eficconscomb}</li>
                        <li><img className="imagerotulagem" src={BaseImgPath+"/images/adpavmolhabk.png"} alt="aderencia em pavimento molhado" />{row.aderpisomolh}</li>
                        <li>
                            <img className="imagerotulagem" src={BaseImgPath+"/images/nivelruido.png"} alt="nivel de ruido" />
                            {row.ruidoexterior}
                        </li>
                    </ul>
                </div>
            ),
            center:true,
            hide: 'md',
        },
        {
            id: 'foto',
            name: t(currentLanguage+'_etiq-foto-loja'),
            // width: '7%',
            grow: 1,
            center:true,
            cell: (row,index) => { 
                return (
                    <a type="button" data-bs-toggle="modal" data-bs-target="#modalDestaqueAlternativa" className="fotog" onClick={() => {setDataProduto(row)}}>
                        <MdImageSearch/>
                    </a>
                )
            },
            // hide: 'sm',
        },{
            id: 'comprar',
            name: t(currentLanguage+'_comprar-loja'),
            // width: '15%',
            grow: 1,
            right: true,
            cell: (row, index) => {
                return(
                    <form className={"form-inline justify-content-between align-items-between"} key={row.stamp}>
                        <input className={"form-control"} type="number" name={row.referencia} value={number[row.referencia]} key={row.referencia} onChange={(e)=>handleQty(e, row.stock)} min={0} max={row.stock} defaultValue="1" />
                        {!loadingAddCartItem[row.referencia] ? (
                                    <>
                                        {successAddCartItem[row.referencia] === true && (
                                        <a className={"btn btn-success"}>
                                            <i className={"bi bi-check-circle-fill p-2"}></i>
                                        </a>
                                        )}
                                        {noStockAddCartItem[row.referencia] === true && (
                                            <a className={"btn btn-warning"}>
                                                 <i className={"bi bi-box-seam p-2"}></i>
                                            </a>
                                        )}
                                        {!successAddCartItem[row.referencia] && !noStockAddCartItem[row.referencia] && (
                                            <a className={`btn ${props.difColorIfInCart && cartItems.find(cartItem => (cartItem.referencia) === (row.referencia)) ? "btn-success" : "btn-primary"}`} onClick={(e)=>handleSubmit(e,row.referencia, row.stamp)}>
                                                <i className={"bi bi-cart3 p-2"}></i>
                                            </a>
                                        )}
                                    </>
                                ):(
                                    <a className={"btn btn-primary "}><Spinner/></a>
                                )}
                        {/* {!loadingAddCartItem[row.referencia] ? (
                            <a className={"btn btn-primary "} onClick={(e)=>handleSubmit(e,row.referencia, row.stamp)}><i className={"bi bi-cart3 p-2"}></i></a>
                        ):(
                            <a className={"btn btn-primary "}><Spinner/></a>
                        )} */}
                    </form>
                )
            }    
        }  
    ];

    const conditionalRowStyles = [
        {
            when: row => row.noStock,
            style: {
                display:'none',
            },
        },
    ];

    // useEffect(() => {
    //     const getData = async () => {
    //         if(!loading && artigos.length < 1 && props.isExpanded && referencia){
    //             setLoading(true)
    //             try {
    //                 await getArtigos()
    //                 setLoading(false)
    //             } catch (error) {
    //                 setLoading(false)
    //             }
    //         }else if(!props.isExpanded){
    //             setRefencia("")
    //         }
    //     }
    //     getData()
    // }, [referencia]);

    // if(loading){
    //     return (
    //         <div className="d-flex justify-content-center p-3">
    //             <div className="spinner-border" role="status">
    //                 <span className="visually-hidden">Aguarde...</span>
    //             </div>
    //         </div>
    //     )
    // }else{
        return (     
            <section style={{paddingTop:'inherit',paddingBottom:'inherit'}}>
                <div className="container dagrelhab2b this-alternativa">
                    <div className="row">                
                        <div className="col-12 col-lg-12 tabelaartigos alternativas">
                            <ProdutoDestaqueAlternativa produto={dataProduto}/>
                            {artigos && artigos.length > 0 ?(
                                <DataTable
                                    persistTableHead = {true}
                                    columns={columns}
                                    data={artigos}
                                    conditionalRowStyles={conditionalRowStyles}
                                    highlightOnHover={true}
                                    noDataComponent={
                                        <div className="w-100 h-100 bg-secondary text-center" style={{padding: '24px' }}>
                                            {t(currentLanguage+'_nao-encontramos-artigos-loja')}
                                        </div>
                                    }
                                    striped
                                />
                            ):(
                                <div className="p-3">
                                    {t(currentLanguage+'_nao-encontramos-artigos-loja')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        )
    // }
}

export default Alternativas