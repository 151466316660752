import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../../contexts/authContext.jsx";
import { Link } from "react-router-dom";
import './perfil.scss'
import MetaPages from "../../components/MetaPages.jsx";
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
import { getPageAPI } from "../../data/tpagina.jsx";
import { getUserDataPHC } from "../../data/apiPHC.jsx";
import NavAreaPrivada from "../../components/NavAreaPrivada.jsx";
import LoadingPage from "../../components/LoadingPage.jsx";
import ModalResetPassword from "../../components/ModalResetPassword.jsx";
import ModalAlterarPerfil from "../../components/ModalAlterarPerfil.jsx";

import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const Perfil = () => {
    const { userData } = useContext(AuthContext)
    const [loadingUserData, setLoadingUserData] = useState(true)
    const [loading, setLoading] = useState(false);
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [dataCliente, setDataCliente] = useState("")

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("perfil", "perfil", currentLanguage)
        setPage(response)
    }

    async function getUserData(){
        if(userData){
            const response = await getUserDataPHC(userData.no)
            setDataCliente(response)
            setLoadingUserData(false)
        }
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getUserData()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>
            <ModalResetPassword/>
            <ModalAlterarPerfil/>
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="reservada">
                <div className="container-fluid">
                    <div className="row">
                        <NavAreaPrivada></NavAreaPrivada>
                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                            {t(currentLanguage+'_area-privada')}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t(currentLanguage+'_perfil')}
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="h2">{t(currentLanguage+'_perfil')}</h1>
                            <div className="form_geral">
                                <div className="row">
                                    {!loadingUserData ? (
                                        <div className="col-12 col-xl-12 mb-4 mb-lg-0 form_geral_container">
                                            <form id="form_geral_form">
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                                    <div className="w-100 p-0">
                                                        <div className="row row-gap-3">
                                                            <div className="col col-12">
                                                                <label className="w-100">{t(currentLanguage+'_nome-empresa')}</label>
                                                                <input value={dataCliente.nome} id="showNomeEmpresa" type="text" className="input_field" placeholder={t(currentLanguage+'_nome-empresa')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                                    <div className="w-100 p-0">
                                                        <div className="row row-gap-3">
                                                            <div className="col col-12">
                                                                <label className="w-100">{t(currentLanguage+'_nif')}</label>
                                                                <input value={dataCliente.ncont} type="text" id="showNIF" className="input_field threeinput" placeholder={t(currentLanguage+'_nif')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                                    <div className="w-100 p-0">
                                                        <div className="row row-gap-3">
                                                            <div className="col col-12">
                                                                <label className="w-100">{t(currentLanguage+'_morada')}</label>
                                                                <input value={dataCliente.morada} type="text" id="showMorada" className="input_field" placeholder={t(currentLanguage+'_morada')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between ">
                                                    <div className="w-100 p-0">
                                                        <div className="row  row-gap-3">
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_localidade')}</label>
                                                                <input value={dataCliente.localidade} type="text" id="showLocalidade" className="input_field" placeholder={t(currentLanguage+'_localidade')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_codigo-postal')}</label>
                                                                <input value={dataCliente.cpostal} type="text" id="showCPostal" className="input_field" placeholder={t(currentLanguage+'_codigo-postal')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between">
                                                    <div className="w-100 p-0">
                                                        <div className="row  row-gap-3">
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_distrito')}</label>
                                                                <input value={dataCliente.distrito} type="text" id="showDistrito" className="input_field" placeholder={t(currentLanguage+'_distrito')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_pais')}</label>
                                                                <input value={dataCliente.descpais} type="text" id="showCPostal" className="input_field" placeholder={t(currentLanguage+'_pais')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group d-flex flex-md-row flex-column justify-content-between align-items-between">
                                                    <div className="w-100 p-0">
                                                        <div className="row  row-gap-3">
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_email')}</label>
                                                                <input value={dataCliente.email} type="email" id="showEmail" className="input_field" placeholder={t(currentLanguage+'_email')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                            <div className="col col-12 col-md-6">
                                                                <label className="w-100">{t(currentLanguage+'_telefone')}</label>
                                                                <input value={dataCliente.telefone} type="tel" id="showTelefone" className="input_field" placeholder={t(currentLanguage+'_telefone')} required="required" disabled={true} data-error="Campo obrigatório."/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <button data-bs-toggle="modal" data-bs-target="#modalAlterarPassword" className="btn btn-md btn-secondary p-2 me-3">
                                                {t(currentLanguage+"_perfil-alterar-palavra-passe")}
                                            </button>
                                            <button data-bs-toggle="modal" data-bs-target="#modalAlterarPerfil" className="btn btn-md btn-warning p-2">
                                                {t(currentLanguage+"_btn-pedido-alterar-perfil")}
                                            </button>
                                        </div>
                                    ):(
                                        <LoadingPage></LoadingPage>
                                    )}                 
                                </div>  
                            </div>
                        </main>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Perfil