import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ManutContext } from '../contexts/manutContext';
import Cookies from 'js-cookie';
import axios from 'axios';

const apiUrl = import.meta.env.VITE_API_URL

const ValidateIsManut = ({children}) => {
    const [loading, setLoading] = useState(false)
    const { manutAuth, setManutAuth } = useContext(ManutContext)
    const location = useLocation()

    const validateManut = async () => {
        const codigo = Cookies.get("codigo")
        try {
            const response = await axios.get(apiUrl+"validate/codigo/manutencao/"+codigo)
            if(response.data.status === 200){
                return setManutAuth(true)
            }
            setManutAuth(false)
        } catch (error) {
            setManutAuth(false)
        }
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                await validateManut()
                setLoading(false)
            }
        }
        getData()
    }, [location]);

    if( manutAuth === false || manutAuth === true ){
        return children;
    }
};

export default ValidateIsManut;