import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { CartContext } from '../contexts/cartContext';

const ModalDeleteCartItem = () => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const { deleteCartItem, itemDelete } = useContext(CartContext)

    let bodyText = t(currentLanguage+"_remover-artigo-carrinho-body")
    bodyText = bodyText.replace(/%NOMEPRODUTO%/g, itemDelete.nome);
    // const handleCloseModal = () => {
    //     document.getElementById('closeModalDeleteCartItem').click();
    // }

    return (
        <div className="modal fade" id="modalDeleteCartItem" tabindex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            {t(currentLanguage+"_remover-artigo-carrinho")}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {bodyText}
                    </div>
                    <div className="modal-footer">
                        <button id='closeModalDeleteCartItem' className='btn btn-light p-2' data-bs-dismiss="modal">
                            {t(currentLanguage+"_cancelar")}
                        </button>
                        <button onClick={() => deleteCartItem(itemDelete.referencia)} className='btn btn-danger p-2' data-bs-dismiss="modal">
                            {t(currentLanguage+"_remover")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDeleteCartItem