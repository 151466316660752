import { useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next';
import { getPageAPI } from "../../data/tpagina";
import { getParameterByAliasAPI } from "../../data/parameter";
import MetaPages from "../../components/MetaPages";
import { passwordRecoverPHC } from "../../data/apiPHC";
import { Link } from "react-router-dom";
import Captcha from "../../components/Captcha";
import './recuperar.scss'

import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const Recuperar = () => {
    const reCaptchaRef = useRef()
    const [recaptchaType, setRecaptchaType] = useState(0)
    const [loadingPage, setLoadingPage] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t, i18n: {language}} = useTranslation();
    const [currentLanguage] = useState(language);
    const [page, setPage] = useState("")
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")

    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('')

    const [data, setData] = useState({ email : "", alias: "recoverpassword", code: currentLanguage})

    const [errorCaptcha, setErrorCaptcha] = useState(false)

    const [captchaValid, setCaptchaValid] = useState(false)

    function handleValidateCaptcha(data){
        setCaptchaValid(data)
    }

    function handleSetErrorCaptcha(data){
        setErrorCaptcha(data)
    }

    async function handleSubmitResetPassword(e){
        e.preventDefault()
        if(!loading){
            if((recaptchaType === 0 && captchaValid) || recaptchaType === 1){
                setLoading(true);
                try {
                    let token
                    if(recaptchaType === 1){
                        token = await reCaptchaRef.current.executeAsync();
                        reCaptchaRef.current.reset();
                    }
                    await passwordRecoverPHC(data, token);
                    setLoading(false);
                    window.location.replace(VITE_PUBLIC_ROOT+'/'+currentLanguage+'/obrigado/recuperar-password');
                } catch (error) {
                    setLoading(false);
                }
            }else{
                setErrorCaptcha(true)
            }
        }
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente");
        setNomeCliente(response.varchar);
    }

    async function getPage(){
        const response = await getPageAPI("recuperar", "password recover", currentLanguage)
        setPage(response)
    }

    async function getRecaptchaType(){
        const response = await getParameterByAliasAPI("recaptchatype")
        setRecaptchaType(response.int)
    }

    async function getRecaptchaSiteKey(){
        const response = await getParameterByAliasAPI("recaptchasitekey")
        setRecaptchaSiteKey(response.varchar)
    }

    useEffect(() => {
        const getData = async () => {
            if(!loadingPage){
                setLoadingPage(true)
                try {
                    await Promise.all([
                        getNomeCliente(),
                        getPage(),
                        getRecaptchaType(),
                        getRecaptchaSiteKey()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoadingPage(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="top-imagem">
                <div className="container-fluid">
                    <div className="row justify-content-center img-top-content">
                        <div className="col-12 this-center-all">
                            <div className="wrapper-content ">
                                <h2 className="display-5-b text-white">{t(currentLanguage+'_recuperar-acesso')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="form_geral">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="form_geral_container">
                                    <div className="form_geral_title s-text">
                                    {t(currentLanguage+'_preencha-email-acesso')}<br/>
                                        <h6>{t(currentLanguage+'_receber-email-info-necessaria')}<br/></h6>
                                    </div>
                                    <form onSubmit={(e) => handleSubmitResetPassword(e)} id="form_geral_form">
                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input value={data.email} onChange={(e) => setData({...data, email: e.target.value})} type="email" className=" input_field" placeholder={t(currentLanguage+'_email')} required data-error="Campo obrigatório."/>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input type="checkbox" className="form-check-input" id="politica" required/>
                                            <label className="form-check-label" for="politica">
                                                <span className="text-black">
                                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/politica-privacidade"}>
                                                        {t(currentLanguage+'_aceito-politica-privacidade')}
                                                    </Link>
                                                </span>
                                            </label>
                                        </div>
                                        {recaptchaType === 0 &&(
                                            <>
                                                {!captchaValid &&(
                                                    <Captcha handleValidateCaptcha={handleValidateCaptcha} errorCaptcha={errorCaptcha} handleSetErrorCaptcha={handleSetErrorCaptcha}/>
                                                )}
                                                {captchaValid &&(
                                                    <span className="text-success">
                                                        {t(currentLanguage+"_capcha-success-message")}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        {recaptchaType === 1 && recaptchaSiteKey &&(
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                sitekey={recaptchaSiteKey}
                                                badge="bottomright"
                                                size="invisible"
                                            />
                                        )}
                                        <div className="form_geral_button text-center">
                                            <button type="submit" className="btn btn-secondary btn-black form_geral_submit_button half-w">
                                                {loading ? (
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">{t(currentLanguage+'_aguarde')}</span>
                                                    </div> 
                                                ):(
                                                    <span>{t(currentLanguage+'_recuperar-acesso')}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        
        </>
    )
}

export default Recuperar