import axios from "axios";
import { Base64 } from "js-base64";
import { Notify } from "../services/Notify.service";
const apiUrl = import.meta.env.VITE_API_URL

export async function addCartItemAPI(data,t,currentLanguage){
    try {
        if(!data){
            return
        }
        const dataEncoded = Base64.encode(JSON.stringify(data))
        
        const response = await axios.post(apiUrl+"addcartitem/", {
            data: dataEncoded
        })
        Notify("success", t(currentLanguage+"_produto-adicionado-carrinho-sucesso"))
        return console.log(response.data.message)
    } catch (error) {
        if(error.response.data.status === 400){
            Notify("warn", t(currentLanguage+"_qttproduto-excede-stock"))
            throw "nostock"
        }
        throw error
    }
}

export async function deleteCartItemAPI(no, productRef,t,currentLanguage){
    try {
        const response = await axios.delete(`${apiUrl}cartdeleteproduct/${no}/${productRef}`)
        Notify("success", t(currentLanguage+"_produto-apagado-carrinho-sucesso"))
        return response.data
    } catch (error) {
        throw error
    }
}
