import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next'

const Captcha = ({handleValidateCaptcha, errorCaptcha, handleSetErrorCaptcha}) => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [captchaText, setCaptchaText] = useState(''); 
    const [userInput, setUserInput] = useState(''); 
    const canvasRef = useRef(null); 

    useEffect(() => { 
        const canvas = canvasRef.current; 
        const ctx = canvas.getContext('2d'); 
        initializeCaptcha(ctx); 
    }, []); 

    const generateRandomChar = (min, max) => 
        String.fromCharCode(Math.floor (Math.random() * (max - min + 1) + min)
    ); 
  
    const generateCaptchaText = () => { 
        let captcha = ''; 
        for (let i = 0; i < 3; i++) { 
            captcha += generateRandomChar(65, 90); 
            captcha += generateRandomChar(97, 122); 
            captcha += generateRandomChar(48, 57); 
        } 
        return captcha.split('').sort(() => Math.random() - 0.5).join(''); 
    }; 
  
    const drawCaptchaOnCanvas = (ctx, captcha) => { 
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); 
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)']; 
        const letterSpace = 150 / captcha.length; 
        for (let i = 0; i < captcha.length; i++) { 
            const xInitialSpace = 25; 
            ctx.font = '20px Roboto Mono'; 
            ctx.fillStyle = textColors[Math.floor( 
                Math.random() * 2)]; 
            ctx.fillText( 
                captcha[i], 
                xInitialSpace + i * letterSpace, 
                  
                // Randomize Y position slightly 
                Math.floor(Math.random() * 16 + 25), 
                100 
            ); 
        } 
    }; 
  
    const initializeCaptcha = (ctx) => { 
        setUserInput(''); 
        const newCaptcha = generateCaptchaText(); 
        setCaptchaText(newCaptcha); 
        drawCaptchaOnCanvas(ctx, newCaptcha); 
    }; 
  
    const handleUserInputChange = (e) => { 
        setUserInput(e.target.value);
        if(e.target.value === captchaText){
            handleValidateCaptcha(true)
        }
        handleSetErrorCaptcha(false)
    }; 
  
    // const handleCaptchaSubmit = () => { 
    //     if (userInput === captchaText) { 
    //         handleValidateCaptcha()
    //     } else { 
    //         alert('Incorrect'); 
    //     } 
    // };

    useEffect(() => {
        if(errorCaptcha){
            const canvas = canvasRef.current; 
            const ctx = canvas.getContext('2d'); 
            initializeCaptcha(ctx); 
        }
    }, [errorCaptcha])

    return (
       <div>
            <div className="w-100 mt-4">
                <canvas className="border rounded" ref={canvasRef} 
                    width="200"
                    height="70"> 
                </canvas>
            </div>
            <input 
                type="text"
                id="user-input"
                placeholder={t(currentLanguage+"_capcha-input-placeholder")}
                value={userInput} 
                onChange={handleUserInputChange}
                className={`form-control mt-2 ${errorCaptcha ? "border-danger" : ""}`}
                style={{width: 200}}
            />
            {/* <div className="mt-2 gap-2 d-flex">
                <button className="btn btn-secondary p-2"  onClick={() => initializeCaptcha(canvasRef.current.getContext('2d'))}> 
                    Recarregar
                </button>
                <button className="btn btn-primary p-2"  onClick={() => handleCaptchaSubmit()}> 
                    Confirmar
                </button>
            </div> */}
       </div>
    )
}

export default Captcha