import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from "../../components/MetaPages";
import { getPageAPI } from "../../data/tpagina";
import { getParameterByAliasAPI } from "../../data/parameter";
import { Base64 } from 'js-base64';
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia";
import { Link } from "react-router-dom";

import ReactGA from 'react-ga4';

import './notfound.scss'

const Notfound = () => {
    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
    const { t } = useTranslation();
    const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT
    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [page, setPage] = useState("")
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")

    const [ image, setImage] = useState("")

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("notfound", currentLanguage)
        setImage(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        if(!page){
            const response = await getPageAPI("notfound", "notfound", currentLanguage)
            setPage(response)
        }
    }
    
    useEffect(() => {
        getNomeCliente()
        getPage()
        getImage()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])
    
    return (
        <>  
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            {image &&(
                <div className="d-flex align-items-center justify-content-center notfound" style={{backgroundImage : 'url('+BaseImgPath+image.path+')'}}>
                    <div className="text-center text-white">
                        <h1 className="display-1 fw-bold">404</h1>
                        {page &&(
                            <p className="lead" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}>
                                {/* {t('txtoops404')} */}
                            </p>
                        )}
                        <Link className="btn btn-secondary btred-all" to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/home"}>
                            {t(currentLanguage+'_homemenu')}
                        </Link>
                    </div>
                </div> 
            )}
        </>
    )
}

export default Notfound