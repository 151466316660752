import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkToken } from "./dataStorage.service";
import axios from "axios";
import { logout } from "./dataStorage.service";

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
const apiUrl = import.meta.env.VITE_API_URL

export function AlreadyLogged({ children }) {
    const location = useLocation()
    const [isValid, setIsValid] = useState(null);
    const token = checkToken();

    useEffect(() => {
        const validate = async () => {
            if (!token) {
                return setIsValid(false);
            }
            try {
                const response = await axios.get(apiUrl + "authvalidate/", {
                    headers: {
                        'Token': token
                    }
                });
                if(response.data.status === 200){
                    return setIsValid(true);
                }
                setIsValid(false);
            } catch (error) {
                logout();
                setIsValid(false);
            }
        };
        validate();
    }, [token, location]);

    useEffect(() => {
        if (isValid === true) {
            window.location.replace(VITE_PUBLIC_ROOT);
        }
    }, [isValid]);

    if (isValid === false) {
        return children;
    }

    return null;
}

export function PrivateRoute({ children }) {
    const location = useLocation()
    const [isValid, setIsValid] = useState(null);
    const token = checkToken();

    useEffect(() => {
        const validate = async () => {
            if (!token) {
                logout()
                window.location.replace(VITE_PUBLIC_ROOT);
                return;
            }
            try {
                const response = await axios.get(apiUrl + "authvalidate/", {
                    headers: {
                        'Token': token
                    }
                });
                if(response.data.status === 200){
                    return setIsValid(true);
                }
                return setIsValid(false)
            } catch (error) {
                logout()
                window.location.replace(VITE_PUBLIC_ROOT);
            }
        };
        validate();
    }, [token, location]);

    if (isValid === null) {
        return null;
    }else if (isValid === false){
        logout()
        return window.location.replace(VITE_PUBLIC_ROOT);
    }

    return children;
}