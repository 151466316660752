import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function getEmpresaPage(language){
    try {
        const response = await axios.get(apiUrl+"page/empresa/"+language+"/")
        return response.data.page
    } catch (error) {
        console.log(error.response.data.message)
    }
}

