import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from './App.jsx'
import './i18n';
import axios from "axios"
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

axios.defaults.headers.common['Authorization'] = `Bearer ${import.meta.env.VITE_API_AUTH_KEY}`;

// Import our custom CSS
import './scss/styles.scss' 
import { CartProvider } from "./contexts/cartContext.jsx";
import { AuthProvider } from "./contexts/authContext.jsx";
import { ManutProvider } from "./contexts/manutContext.jsx";

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ManutProvider>
      <AuthProvider>
        <CartProvider>
          <App />
        </CartProvider>
      </AuthProvider>
    </ManutProvider>
  </React.StrictMode>,
)

