import { Base64 } from "js-base64";
import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function reclamarArtigoAPI(data){
    try {
        const dataEncoded = Base64.encode(JSON.stringify(data))
        const response = await axios.post(apiUrl+"reclamarproduto/", {
            data: dataEncoded
        })
        console.log(response.data.message)
    } catch (error) {
        console.log(error.response.data.message)
        throw error
    }
}