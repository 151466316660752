import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

import './btwob.scss'

const ProdutoDestaque = (props) => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [img, setImg] = useState("")
    const [img2, setImg2] = useState("")
    let sliderRef = useRef(null)

    var settings = {
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            }
        ]
    };
    

    useEffect(() => {
        if(props.produto){
            if(props.produto.imagem){
                let imagem = props.produto.imagem
                console.log(imagem)
                imagem = imagem.replace(/\\/g, '/');
                console.log(imagem)
                let imagemIndex = imagem.indexOf("\PHCIMAGENS")
                // let extensaoIndex = imagem.indexOf("_RESULTADO" || ".")
                // let extensao = imagem.substr(extensaoIndex)
                // let extensaoNormal = extensao
                // extensao = extensao.toLowerCase()
                imagem = imagem.slice(imagemIndex)
                imagem = imagem.toUpperCase()
                // imagem = imagem.replace(extensaoNormal, extensao)
                /* VALIDAR COM FS SE EXISTE IMG LOCAL*/
                setImg(BaseImgPath+"/"+imagem)
            }
            if(props.produto.imagem2){
                let imagem = props.produto.imagem2
                imagem = imagem.replace(/\\/g, '/');
                let imagemIndex = imagem.indexOf("\PHCIMAGENS")
                // let extensaoIndex = imagem.indexOf("_RESULTADO" || ".")
                // let extensao = imagem.substr(extensaoIndex)
                // let extensaoNormal = extensao
                // extensao = extensao.toLowerCase()
                imagem = imagem.slice(imagemIndex)
                imagem = imagem.toUpperCase()
                // imagem = imagem.replace(extensaoNormal, extensao)
                setImg2(BaseImgPath+"/"+imagem)
            }
        }
        if(sliderRef){
            sliderRef.slickGoTo(0)
        }
    }, [props.produto])

    return (
        <div className="modal " tabIndex="-1" id={"modalTeste"}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.produto.nome}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="col col-lg-12">
                            <h6>{t(currentLanguage+'_descricao-tecnica-loja')}</h6>
                            <p>
                                {props.produto["desc"+currentLanguage]}
                            </p>
                        </div>
                        <div className="col col-lg-12 imagensartigomod">
                            <Slider ref={slider => {sliderRef = slider;}} {...settings} key={"refartigo"}>
                                    {props.produto.imagem &&(
                                        <div className="insidearticle" key="1">
                                            <a href={img} key="a1" target="_blank">
                                                <img src={img} className="img-responsive" alt={props.produto.nome} key="b1" />
                                            </a>
                                        </div>
                                    )}
                                    {props.produto.imagem2 && (
                                        <div className="insidearticle" key="2">
                                            <a href={img2} target="_blank" key="a2">
                                                <img src={img2} className="img-responsive" alt={props.produto.nome} key="b2" />
                                            </a>
                                        </div>
                                    )}
                            </Slider>                                                          
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            {t(currentLanguage+'_fechar-loja')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProdutoDestaqueAlternativa = (props) => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [img, setImg] = useState("")
    const [img2, setImg2] = useState("")

    var settings = {
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            }
        ]
    };
    

    useEffect(() => {
        if(props.produto){
            if(props.produto.imagem){
                let imagem = props.produto.imagem
                imagem = imagem.replace(/\\/g, '/');
                let imagemIndex = imagem.indexOf("\PHCIMAGENS")
                // let extensaoIndex = imagem.indexOf("_RESULTADO" || ".")
                // let extensao = imagem.substr(extensaoIndex)
                // let extensaoNormal = extensao
                // extensao = extensao.toLowerCase()
                imagem = imagem.slice(imagemIndex)
                imagem = imagem.toUpperCase()
                // imagem = imagem.replace(extensaoNormal, extensao)
                /* VALIDAR COM FS SE EXISTE IMG LOCAL*/
                setImg(BaseImgPath+"/"+imagem)
            }
            if(props.produto.imagem2){
                let imagem = props.produto.imagem2
                imagem = imagem.replace(/\\/g, '/');
                let imagemIndex = imagem.indexOf("\PHCIMAGENS")
                // let extensaoIndex = imagem.indexOf("_RESULTADO" || ".")
                // let extensao = imagem.substr(extensaoIndex)
                // let extensaoNormal = extensao
                // extensao = extensao.toLowerCase()
                imagem = imagem.slice(imagemIndex)
                imagem = imagem.toUpperCase()
                // imagem = imagem.replace(extensaoNormal, extensao)
                setImg2(BaseImgPath+"/"+imagem)
            }
        }
    }, [props.produto])

    return (
        <div className="modal " tabIndex="-1" id={"modalDestaqueAlternativa"}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.produto.nome}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="col col-lg-12">
                            <h6>{t(currentLanguage+'_descricao-tecnica-loja')}</h6>
                            <p>
                                {props.produto["desc"+currentLanguage]}
                            </p>
                        </div>
                        <div className="col col-lg-12 imagensartigomod">
                            <Slider {...settings} key={"refartigo"}>
                                    {props.produto.imagem &&(
                                        <div className="insidearticle" key="1">
                                            <a href={img} target="_blank" key="a1">
                                                <img src={img} className="img-responsive" alt={props.produto.nome} key="b1" />
                                            </a>
                                        </div>
                                    )}
                                    {props.produto.imagem2 && (
                                        <div className="insidearticle" key="2">
                                            <a href={img2} target="_blank" key="a2">
                                                <img src={img2} className="img-responsive" alt={props.produto.nome} key="b2" />
                                            </a>
                                        </div>
                                    )}
                            </Slider>                                                          
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            {t(currentLanguage+'_fechar-loja')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    ProdutoDestaque,
    ProdutoDestaqueAlternativa
}