import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

// Set token || SESSION IF NOT REMEMBER || LOCALSTORAGE IF REMEMBER
export const setSessionToken = (token) => {
    // sessionStorage.setItem("kitkat", token)
    Cookies.set('kitkat', token, { secure: false });
}
export const setLocalToken = (token) => {
    // localStorage.setItem("kitkat", token)
    // 1/48 ===== 30 MINUTOS
    Cookies.set('kitkat', token, { expires: 365, secure: false });
}
// GET TOKENS
export const getSessionToken = () => {
    // sessionStorage.getItem("kitkat")
    return Cookies.get('kitkat')
}
export const getLocalToken = () => {
    // localStorage.getItem("kitkat")
    return Cookies.get('kitkat')
}

// GET DATA FROM TOKEN
export const getTokenData = () => {
    if(getSessionToken()){
        try {
            const tokenData = jwtDecode(getSessionToken())
            return tokenData
        } catch (error) {
            logout()
        }
    }else if(getLocalToken()){
        try {
            const tokenData = jwtDecode(getLocalToken())
            return tokenData
        } catch (error) {
            logout()
        }
    }
    return false
}

export const checkToken = () => {
    var token = getSessionToken()
    if(!token){
        token = getLocalToken()
    }
    if(token){
        return token
    }
    return false
}

//LOGOUT
export const logout = () => {
    // localStorage.removeItem("kitkat");
    // sessionStorage.removeItem("kitkat");
    Cookies.remove('kitkat')
}

