import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
import { Link } from "react-router-dom";

const UserBar = ({tokenData}) => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);

    return(
      <li className="nav-item">
        <Link className={`bi-icon nav-link ${(location.pathname === `/${currentLanguage}/dashboard` || location.pathname === `/${currentLanguage}/perfil`) ? "active" : ""}`} to={!tokenData ? (
              `${VITE_PUBLIC_ROOT}/${currentLanguage}/login`
            ):(
              `${VITE_PUBLIC_ROOT}/${currentLanguage}/dashboard`
            )}>
            <span><i className="bi bi-person-circle p-2"></i>{tokenData &&(tokenData.name)}</span>
        </Link>
          
      </li>
    )
}

export default UserBar