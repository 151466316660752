import { useEffect} from "react"

const MetaPages = (props) => {
    useEffect(() => {
        document.title=`${props.pageTitle}`;
        document.querySelector("meta[name='description']").setAttribute("content", `${props.pageMetaSummary}`);
        document.querySelector("meta[name='author']").setAttribute("content", `${props.pageMetaAuthor}`);
        document.querySelector("meta[name='keywords']").setAttribute("content", `${props.pageMetaTags}`);
        document.querySelector("meta[name='robots']").setAttribute("content", `${props.robots}`);
        //  meta sociais e indexacao
        document.querySelector("meta[property='og:type']").setAttribute("content", `${props.ogtype}`);
        document.querySelector("meta[property='og:url']").setAttribute("content", `${props.ogurl}`);
        document.querySelector("meta[property='og:title']").setAttribute("content", `${props.pageSeoTitle}`);
        document.querySelector("meta[property='og:description']").setAttribute("content", `${props.pageSeoDescription}`);
        document.querySelector("meta[property='og:image']").setAttribute("content", `${props.pageSeoImage}`);
        document.querySelector("meta[property='og:image:alt']").setAttribute("content", `${props.pageSeoImageAlt}`);
    }, []);
    return
};


export default MetaPages;