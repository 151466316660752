import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function getMultimediaByCategoryAndLanguage(category,language){
    try {
        const response = await axios.get(apiUrl+"multimedia/category/"+category+"/"+language)
        return response.data.multimedia
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export async function getMultimediaByLanguage(language){
    try {
        const response = await axios.get(apiUrl+"multimedia/language/"+language)
        return response.data.multimedia
    } catch (error) {
        console.log(error.response.data.message)
    }
}

export async function getMultimediaByAliasAndLanguage(alias,language){
    try {
        const response = await axios.get(apiUrl+"multimedia/file/"+alias+"/"+language)
        return response.data.multimedia
    } catch (error) {
        console.log(error.response.data.message)
    }
}