import React from 'react'

const LoadingPage = () => {
  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center hideoverflow">
        <div className="spinner-border" role="status"></div>
    </div>
  )
}

export default LoadingPage