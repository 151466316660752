import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CartContext } from '../contexts/cartContext';

const UpdateCartOnPageChange = ({children}) => {
  const { updateCart  } = useContext(CartContext);
  const { pathname } = useLocation();

  useEffect(() => {
    updateCart()
  }, [pathname]);

  return children;
};

export default UpdateCartOnPageChange;