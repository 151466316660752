import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const VITE_API_URL = import.meta.env.VITE_API_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${import.meta.env.VITE_API_AUTH_KEY}`;
import axios from 'axios';

// var thislngapp = await axios.get(`${VITE_API_URL}parameter/alias/defaultlang`).varchar
const thislngapp = "pt"

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupQuerystring: 'lng'
};

// i18n.on('initialized',async function(options) {
//   // const response = await axios.get(`${VITE_API_URL}parameter/alias/linguagensativas`)
//   const responseDefaultLang = await axios.get(`${VITE_API_URL}parameter/alias/defaultlang`)
//   // const lngativas = response.data.parameter.varchar
//   const thislngappp = responseDefaultLang.data.parameter.varchar
//   thislngapp = thislngappp
//   // const arrayLanguage = lngativas.split(",");
//   // return thislngapp, arrayLanguage
// })
//  Proteger o inicio da aplicação com a linguagem default se não existir em sessao
i18n.on('languageChanged', async function (lng) {
  if(lng === 'pt-PT'){
    i18n.changeLanguage(i18n.options.fallbackLng[0]);
  }
  const translation = await axios.get(`${VITE_API_URL}translations/code/${lng}`)
  i18n.addResourceBundle(lng, 'translation', translation.data.translation, true, true);
})

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

  // .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng: 'pt',
    detection: DETECTION_OPTIONS,
    // resources: compiled.resources,
    // resources: {
    //   pt : "",
    //   en : "",
    // },
    fallbackLng: thislngapp, // LINGUA DEFAULT
    debug: false,
    nonExplicitSupportedLngs: false,
    cleanCode: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
});


export default i18n;