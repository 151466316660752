import {useState, useContext} from 'react'
import { AuthContext } from '../contexts/authContext'
import { reclamarArtigoAPI } from '../data/reclamarArtigo'
import { useTranslation } from 'react-i18next'
import Spinner from './Spinner'

const ModalReclamarArtigo = () => {
    const {userData} = useContext(AuthContext)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ assunto : "", mensagem: "", alias: "reclamarproduto", language: currentLanguage, emailCliente: userData.email, nomeCliente: userData.name})

    const handleResetData = () => {
        setData({ assunto : "", mensagem: "", alias: "reclamarproduto", language: currentLanguage, emailCliente: userData.email, nomeCliente: userData.name})
    }
    
    const handleSubmitReclamarArtigo = async (e) => {
        e.preventDefault()
        if(!loading){
            setLoading(true)
            try {
                await reclamarArtigoAPI(data)
                handleResetData()
                hideModal()
            } catch (error) {}
            setLoading(false)
        }
    }

    const hideModal = () => {
        document.getElementById('closeModalReclamar').click();
    }

    return (
        <div className="modal fade" id="modalReclamarArtigo" tabindex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{t(currentLanguage+'_reclamar-artigo')}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-geral">

                            <form id="form_reclamar_artigo" onSubmit={(e) => handleSubmitReclamarArtigo(e)}>
                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                    <div className="w-100 p-0">
                                        <div className="row row-gap-3">
                                            <div className="col col-12">
                                                <label htmlFor='assuntoReclamar' className="w-100 form-label">{t(currentLanguage+'_assunto-modal-reclamar')}</label>
                                                <input onChange={(e) => setData({...data, assunto: e.target.value})} value={data.assunto} id="assuntoReclamar" type="text" className="form-control" placeholder={t(currentLanguage+'_assunto-modal-reclamar')} required data-error="Campo obrigatório."/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                    <div className="w-100 p-0">
                                        <div className="row row-gap-3">
                                            <div className="col col-12">
                                                <label htmlFor='mensagemReclamar' className="w-100 form-label">{t(currentLanguage+'_mensagem-modal-reclamar')}</label>
                                                <textarea onChange={(e) => setData({...data, mensagem: e.target.value})} value={data.mensagem} id="mensagemReclamar" className="form-control" name="message" rows="6" placeholder={t(currentLanguage+'_mensagem-modal-reclamar')} maxLength={500} required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" id='closeModalReclamar' onClick={() => handleResetData()} className="btn btn-light p-2" data-bs-dismiss="modal">{t(currentLanguage+'_cancelar')}</button>
                        <button type="submit" form="form_reclamar_artigo" className="btn btn-secondary p-2">
                            {!loading ? (
                                t(currentLanguage+'_reclamar')
                            ):(
                                <Spinner/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalReclamarArtigo