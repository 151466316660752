import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './authContext';
import { getCartDataPHC} from '../data/apiPHC';
import { deleteCartItemAPI } from '../data/cart';
import { useTranslation } from 'react-i18next';
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { userData } = useContext(AuthContext)
  const [cartItems, setCartItems] = useState([]);
  const [deliveryMode, setDeliveryMode] = useState({})

  const [itemDelete, setItemDelete] = useState({})

  const { t, i18n: {language} } = useTranslation();
  const [currentLanguage] = useState(language);

  const getCartData = async () => {
    if(userData){
      try {
        const response = await getCartDataPHC(userData.no,t,currentLanguage)
        setCartItems(response.products)
        setDeliveryMode(response.modoEntrega)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const updateCart = () => {
    getCartData()
  }

  const deleteCartItem = async (ref) => {
    if(userData && ref){
        try {
            await deleteCartItemAPI(userData.no, ref,t,currentLanguage)
            updateCart()
        } catch (error) {}
    }
  }

  useEffect(() => {
    if(userData){
      updateCart()
    }
  }, [userData])

  return (
    <CartContext.Provider value={{ deliveryMode, cartItems, setCartItems, updateCart, deleteCartItem, itemDelete, setItemDelete }}>
      {children}
    </CartContext.Provider>
  );
};