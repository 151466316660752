import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
// axios.defaults.headers.common['Authorization'] = `Bearer ${import.meta.env.VITE_API_AUTH_KEY}`;
import axios from "axios";
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
const VITE_API_URL = import.meta.env.VITE_API_URL;

const LanguageBar = () => {
    const [ languages, setLanguages] = useState([])
    const { t, i18n: {changeLanguage, language} } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(language);

    async function getLanguagesData(){
      try {
        const lngsAtivasResponse = await axios.get(`${VITE_API_URL}parameter/alias/linguagensativas`);
        const responseLangNomes = await axios.get(`${VITE_API_URL}parameter/alias/langnomes`);
        
        const lngsAtivasData = lngsAtivasResponse.data.parameter.varchar;
        const responseLangNomesData = responseLangNomes.data.parameter.varchar;
        
        const arrayLanguage = lngsAtivasData.split(",");
        const arrayNomeLanguage = responseLangNomesData.split(",");
    
        // Atualizando o estado de languages com a função de atualização do estado
        setLanguages(prevLanguages => {
          const updatedLanguages = [];
          arrayLanguage.forEach((element, index) => {
            updatedLanguages.push({"code": element, "name": arrayNomeLanguage[index]});
          });
          return updatedLanguages;
        });
    
      } catch (error) {
        console.error("Erro ao obter dados de idiomas:", error);
      }
    }

    useEffect(() => {
      getLanguagesData()
    }, [])

    const thisChangeLanguage = (code) => {
      setCurrentLanguage(code); 
      changeLanguage(code);
      //window.location.replace(`${VITE_PUBLIC_ROOT}/${currentLanguage}/home`); // para o build não é mais necessário
      window.location.replace(`${VITE_PUBLIC_ROOT}/`);
    }

    if(languages && languages.length > 0){
      return(
        <>
          <li className="nav-item dropdown linguagem" key={`linguagem`}>
            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={`btlinguagem`}>
              {currentLanguage}
            </a>
            <ul className="dropdown-menu" key={`drplinguagem`}>
              {languages.map(({code, name, index}) => (
                <li style={{backgroundColor: currentLanguage == code ? '#bfbfbf' : '', display: currentLanguage == code ? 'none' : 'block'}} key={code}>
                  <a className="dropdown-item" href="javascript:void(null);" onClick={() => thisChangeLanguage(code)} key={name}>{name}</a>
                </li>
              ))}
            </ul>
          </li>
        </>  
      )
    }else {
      return null
    }
}

export default LanguageBar