import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function getParameterByAliasAPI(alias){
    try {
        const response = await axios.get(apiUrl+"parameter/alias/"+alias)
        return response.data.parameter
    } catch (error) {
        console.log(error.response.data.message)
    }
}
