import React, { useState, useEffect} from "react";
import Slider from "react-slick";
import { getMultimediaByCategoryAndLanguage } from "../data/multimedia";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Carousel() {
  const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
  const [images, setImages] = useState("")
  const {i18n: {language} } = useTranslation();
  const [currentLanguage] = useState(language);

  async function getImagesSlider(){
    if(!images){
      const response = await getMultimediaByCategoryAndLanguage("slidehome",currentLanguage)
      setImages(response)
    }
  }

  useEffect(() => {
    getImagesSlider()
  }, [])

  var settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,      
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              variableWidth: true,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              variableWidth: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
  };

  return (
    <Slider {...settings}>
        {images && images.length > 0 && images.map(( image, imageIndex) => (
            <div className="insideclip" key={imageIndex}>
              <Link to={image.url}>
                <img src={BaseImgPath+image.path} class="img-fluid"  alt={image.description} style={{}}/>
              </Link>
            </div>
        ))}
    </Slider>
  );
}