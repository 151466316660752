import { useState } from 'react';
import { useTranslation } from 'react-i18next'
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const ModalErroFinalizarEncomenda = ({disabled, stockLess, diferentPrice}) => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);

    // let disabledText = t(currentLanguage+"_finalizar-produtos-inativos")
    // if(disabled && disabled.length > 0){
    //     disabledText = disabledText.replace(/%PRODUTOS%/g, disabled.toString());
    // }
    
    let noStockText = t(currentLanguage+"_finalizar-produtos-stock-menor")
    if( stockLess && stockLess.length > 0){
        noStockText = noStockText.replace(/%PRODUTOS%/g, stockLess.toString());
    }

    let priceChangedText = t(currentLanguage+"_finalizar-produtos-preco-alterado")
    if(diferentPrice && diferentPrice.length > 0){
        priceChangedText = priceChangedText.replace(/%PRODUTOS%/g, diferentPrice.toString());
    }
    

    // let bodyText = t(currentLanguage+"_remover-artigo-carrinho-body")
    // bodyText = bodyText.replace(/%NOMEPRODUTO%/g, '');
    // const handleCloseModal = () => {
    //     document.getElementById('closeModalDeleteCartItem').click();
    // }

    const redirect = () => {
        window.location.replace(`${VITE_PUBLIC_ROOT}/${currentLanguage}/carrinho`)
    }

    return (
        <div className="modal fade" data-bs-backdrop="static" id="modalErroFinalizarEncomenda" tabindex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            {t(currentLanguage+"_finalizar-erro")}
                        </h1>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className="modal-body">
                        <p>
                            {disabled ? t(currentLanguage+"_"+disabled) : ""}
                        </p>
                        <p>
                            {stockLess && stockLess.length > 0 ? noStockText: ""}
                        </p>
                        <p>
                            {diferentPrice && diferentPrice.length > 0 ? priceChangedText : ""}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className='btn btn-secondary p-2' data-bs-dismiss="modal" onClick={() => redirect()}>
                            {t(currentLanguage+"_ok")}
                        </button>
                        {/* <Link   to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/carrinho`}>
                            
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalErroFinalizarEncomenda