import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from "../../components/MetaPages.jsx";
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
import { getPageAPI } from "../../data/tpagina.jsx";
import NavAreaPrivada from "../../components/NavAreaPrivada.jsx";
import { Link } from "react-router-dom";
import { getAllEncomendasPHC } from "../../data/apiPHC.jsx";
import { AuthContext } from "../../contexts/authContext.jsx";
import { getProdutosEncomendasPHC } from "../../data/apiPHC.jsx";
import { formatarData } from "../../services/utils.jsx";

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import ReactGA from 'react-ga4';

import './historico_encomendas.scss'

import LoadingPage from "../../components/LoadingPage.jsx";

const HistoricoEncomendas = () => {
    const [loading, setLoading] = useState(false);

    const { userData } = useContext(AuthContext)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;

    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [encomendas, setEncomendas] = useState([])
    const [casasDecimais, setCasasDecimais] = useState(2)
    // const [expandedRow, setExpandedRow] = useState({});
    const [dataRow, setDataRow] = useState({})

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("historico-encomendas", "encomendas", currentLanguage)
        setPage(response)
    }

    async function getEncomendas(){
        const response = await getAllEncomendasPHC(userData.no)
        setEncomendas(response)
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    const getDataEncomenda = async (bostamp) => {
        const response = await getProdutosEncomendasPHC(bostamp)
        setDataRow({...dataRow, [bostamp]:response})
    }

    const handleRowClick = (bostamp) => {
        // setExpandedRow(prevState => ({
        //     ...prevState,
        //     [obrano]: !prevState[obrano]
        // }));
        if(!dataRow[bostamp]){
            getDataEncomenda(bostamp)
        }
    };

    useEffect(() =>{
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getEncomendas(),
                        getCasasDecimais()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>   
            {page && nomeCliente &&(
                <MetaPages pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="reservada">
                <div className="container-fluid">
                    <div className="row">
                        <NavAreaPrivada></NavAreaPrivada>
                        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                            {t(currentLanguage+'_area-privada')}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t(currentLanguage+'_historico-encomendas-title')}
                                    </li>
                                </ol>
                            </nav>
                            <h1 class="h2">
                                {t(currentLanguage+'_historico-encomendas-title')}
                            </h1>
                            <p>
                                {t(currentLanguage+'_historico-encomendas-description')}
                            </p>
                            
                            <div class="row">
                                <div class="col-12 col-xl-12 mb-4 mb-lg-0">
                                    {loading ? (
                                        <LoadingPage/>
                                    ): (
                                        <div class="card">
                                            <h5 class="card-header">{t(currentLanguage+'_lista-de-encomendas')}</h5>
                                            <div class="card-body">
                                                {encomendas.length > 0 ? (
                                                    <div class="table-responsive">
                                                        <table class="table accordion table-striped table-hover tabelamobile">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_n-encomenda')}
                                                                    </th>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_data-encomenda')}
                                                                    </th>
                                                                    <th scope="col">
                                                                        {t(currentLanguage+'_total-encomenda')}
                                                                    </th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {encomendas.map((encomenda) =>{
                                                                    return(
                                                                        <>
                                                                            <tr key={encomenda.obrano} id={`linhaEncomenda${encomenda.obrano}`} onClick={() => handleRowClick(encomenda.bostamp)} data-bs-toggle="collapse" data-bs-target={"#encomenda"+encomenda.obrano}>
                                                                                <th scope="row">{encomenda.obrano} <i class="bi bi-chevron-down"></i></th>
                                                                                <td>
                                                                                    {formatarData(encomenda.dataobra)}
                                                                                </td>
                                                                                <td>
                                                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo12_iva+encomenda.ebo22_iva+encomenda.ebo32_iva+encomenda.ebo42_iva+encomenda.ebo52_iva+encomenda.ebo62_iva+encomenda.etotaldeb)}
                                                                                    {/* {encomenda.tabela1 === "Em Processamento" &&(
                                                                                        <span class="badge text-bg-info">
                                                                                            {t(currentLanguage+"_estado-em-preparacao")}
                                                                                        </span>
                                                                                    )}
                                                                                    {encomenda.tabela1 === "Aguarda Pagamento" &&(
                                                                                        <span class="badge text-bg-warning">
                                                                                            {t(currentLanguage+"_estado-aguarda-pagamento")}
                                                                                        </span>
                                                                                    )}
                                                                                    {encomenda.tabela1 === "Enviado" &&(
                                                                                        <span class="badge text-bg-success">
                                                                                            {t(currentLanguage+"_estado-enviado")}
                                                                                        </span>
                                                                                    )} */}
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr class="collapse accordion-collapse" id={"encomenda"+encomenda.obrano} data-bs-parent=".table">
                                                                                <td colspan="4">
                                                                                    <div class="tabresponsive-mobile">
                                                                                        {/* {expandedRow[encomenda.obrano] &&( */}
                                                                                            <table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100% table-light table-hover">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col">
                                                                                                            {t(currentLanguage+'_referencia-encomenda')}
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            {t(currentLanguage+'_designacao-encomenda')}
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            {t(currentLanguage+'_quant-encomenda')}
                                                                                                        </th>
                                                                                                        <th scope="col">
                                                                                                            {t(currentLanguage+'_preco-unitario-encomenda')}
                                                                                                        </th>
                                                                                                        {/* <th scope="col">
                                                                                                            {t(currentLanguage+'_desconto-encomendas')}
                                                                                                        </th> */}
                                                                                                        <th scope="col" class="">
                                                                                                                {t(currentLanguage+'_ecovalor')}
                                                                                                            </th>
                                                                                                        <th scope="col" class="text-end">
                                                                                                            {t(currentLanguage+'_total-encomenda')}
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                {dataRow[encomenda.bostamp] &&(
                                                                                                        <>
                                                                                                            {dataRow[encomenda.bostamp].map((produto) =>{
                                                                                                                return (
                                                                                                                    <tr>
                                                                                                                        <th scope="row" class="small ">{produto.ref}</th>
                                                                                                                        <td data-label={t(currentLanguage+'_designacao-encomenda')} class="small ">{produto.design}</td>
                                                                                                                        <td data-label={t(currentLanguage+'_quant-encomenda')} class="small ">{produto.qtt || ""}</td>
                                                                                                                        <td data-label={t(currentLanguage+'_preco-unitario-encomenda')} class="small ">{produto.edebito > 0  ? new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(produto.edebito) : ""}</td>
                                                                                                                        <td data-label={t(currentLanguage+'_ecovalor')} class="small">
                                                                                                                            {produto.etecoval > 0  ? new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(produto.etecoval) : ""}
                                                                                                                            </td>
                                                                                                                        {/* <td data-label={t(currentLanguage+'_desconto-encomendas')} className="small">{produto.desconto > 0 ? produto.desconto+" %" : ""}</td> */}
                                                                                                                        <td data-label={t(currentLanguage+'_total-encomenda')} class="text-end small">{produto.ettdeb > 0 ? new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(produto.ettdeb) : ""}</td>
                                                                                                                    </tr>
                                                                                                                )
                                                                                                            })}
                                                                                                            <tr>
                                                                                                                <td colspan="5" class="text-end small"><strong>{t(currentLanguage+'_total-iliquido-encomenda')}</strong></td>
                                                                                                                <td class="text-end small">
                                                                                                                    <strong>
                                                                                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo_totp2)}
                                                                                                                    </strong>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colspan="5" class="text-end small"><strong>{t(currentLanguage+"_descontos")}</strong></td>
                                                                                                                <td class="text-end small">
                                                                                                                    <strong>
                                                                                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.edescc)}
                                                                                                                    </strong>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colspan="5" class="text-end small"><strong>{t(currentLanguage+"_base-incidencia-iva")}</strong></td>
                                                                                                                <td class="text-end small">
                                                                                                                    <strong>
                                                                                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo_2tvall)}
                                                                                                                    </strong>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colspan="5" class="text-end small"><strong>{t(currentLanguage+'_total-iva-encomenda')}</strong></td>
                                                                                                                <td class="text-end small">
                                                                                                                    <strong>
                                                                                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo12_iva+encomenda.ebo22_iva+encomenda.ebo32_iva+encomenda.ebo42_iva+encomenda.ebo52_iva+encomenda.ebo62_iva)}
                                                                                                                    </strong>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colspan="5" class="text-end small"><strong>{t(currentLanguage+'_total-encomenda')}</strong></td>
                                                                                                                <td class="text-end small">
                                                                                                                    <strong>
                                                                                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo12_iva+encomenda.ebo22_iva+encomenda.ebo32_iva+encomenda.ebo42_iva+encomenda.ebo52_iva+encomenda.ebo62_iva+encomenda.etotaldeb)}
                                                                                                                    </strong>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        {/* )} */}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ):(
                                                    <div class="alert alert-primary" role="alert">
                                                        <strong>
                                                            {t((currentLanguage+"_nao-existem-encomendas"))}
                                                        </strong>
                                                    </div>
                                                )}
                                            </div>
                                        </div>   
                                    )}
                                </div>                        
                            </div>                    
                        </main>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HistoricoEncomendas