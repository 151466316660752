//import React, { useEffect, useState } from "react";
import Content from "./routes.jsx"
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4';
import { getParameterByAliasAPI } from "./data/parameter.jsx";
import LoadingPage from "./components/LoadingPage.jsx";
import { NotifyDiv } from "./services/Notify.service.jsx";

function App() {
  const [loading, setLoading] = useState(false)
  const [analyticsCode, setAnalyticsCode] = useState("")
  const [data, setData] = useState("")
  const { i18n, t, i18n: {language} } = useTranslation();

  function getData(){
    if(!data){
      setTimeout(() => {
        setData(i18n.getDataByLanguage(language))
        if(!data){
          getData()
        }
      }, 100);
    }
  }

  async function getAnalyticsCode(){
    const response = await getParameterByAliasAPI("analyticsmeasurementid")
    setAnalyticsCode(response.varchar)
  }

  if(!data){
    getData()
  }

  useEffect(() => {
    const getDataStart = async () => {
      if(!loading && !analyticsCode){
        setLoading(true)
        try {
          await getAnalyticsCode()
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      }
    }
    getDataStart()
  }, [])

  useEffect(() => {
    if(analyticsCode){
      ReactGA.initialize(analyticsCode);
    }
  }, [analyticsCode])

  if(data){
    return (
        <Router>
            <Content></Content>
            <NotifyDiv/>
        </Router>
    );
  }else{
    return (
      <div className="vh-100 w-100 hideoverflow">
        <LoadingPage/>
      </div>
    )
  }
  
}

export default App;