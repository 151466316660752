import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const ModalFinalizarCompra = () => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);

    const handleCloseModal = () => {
        document.getElementById('closeModalFinalizarCompra').click();
    }

    return (
        <div className="modal fade" id="modalFinalizarCompra" tabindex="-1" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            {t(currentLanguage+"_confirmar-compra")}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {t(currentLanguage+"_txt-confirmar-compra")}
                    </div>
                    <div className="modal-footer">
                        <button id='closeModalFinalizarCompra' className='btn btn-light p-2' data-bs-dismiss="modal">
                            {t(currentLanguage+"_cancelar")}
                        </button>
                        <Link className='btn btn-secondary p-2' to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/comprar`} onClick={() => handleCloseModal()}>
                            {t(currentLanguage+"_confirmar-compra")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalFinalizarCompra