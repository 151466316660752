import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { logout } from '../services/dataStorage.service';
import ModalReclamarArtigo from './ModalReclamarArtigo';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import './navareaprivada.scss'

const NavAreaPrivada = () => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const {handleAuthContext} = useContext(AuthContext)
    const location = useLocation()
    function handleLogout(){
        logout()
        handleAuthContext()
        window.location.replace(VITE_PUBLIC_ROOT+"/"+currentLanguage+"/home");
    }

    return (
        <>
            <ModalReclamarArtigo/>
            <nav id="sidebar-lateral" className={`navbar  col-md-3 col-lg-2 d-md-block bg-light sidebar navbar-expand-md  fixed-top`}>
                <div class="container-fluid">
                
                <div className="navbar-brand">
                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                        {t(currentLanguage+'_area-privada')}
                    </Link>
                </div>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarPrivate" aria-controls="navbarPrivate" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                    <div className="position-sticky collapse navbar-collapse" id="navbarPrivate">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/dashboard` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                    <i className="bi bi-back"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_dashboard')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/perfil` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/perfil"}>
                                    <i className="bi bi-person-square"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_perfil')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/loja"}>
                                    <i className="bi bi-bag"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_nova-encomenda')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/encomendas` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/encomendas"}>
                                    <i className="bi bi-card-list"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_encomendas')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/contacorrente` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/contacorrente"}>
                                    <i className="bi bi-list-nested"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_conta-corrente')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/faturas/historico` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/faturas/historico"}>
                                    <i className="bi bi-file-earmark-break"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_historico-faturas')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/encomendas/historico` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/encomendas/historico"}>
                                    <i className="bi bi-card-checklist"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_historico-encomendas')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" type='button' data-bs-toggle="modal" data-bs-target="#modalReclamarArtigo">
                                    <i className="bi bi-book-half"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_reclamar-artigo')}</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === `/${currentLanguage}/carrinho` ? 'active' : ''}`} to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/carrinho"}>
                                    <i className="bi bi-cart3"></i>&nbsp;<span className="ml-2">{t(currentLanguage+'_carrinho')}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link btn btn-secondary logout-bt" onClick={() => handleLogout()}>
                                    <span className="ml-2">{t(currentLanguage+'_sair')}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavAreaPrivada