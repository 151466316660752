import { useLocation } from 'react-router-dom';
import { useState } from "react";
import LanguageBar from "../LanguageBar.jsx";
import UserBar from "../UserBar.jsx";
import CartBar from "../CartBar.jsx";
import { useTranslation } from 'react-i18next';
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
import { Link } from 'react-router-dom';

const Navbaropcoes = ({tokenData}) => {
    let location = useLocation(); 
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language)
    return (
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <a
                    className={`nav-link ${location.pathname === `/${currentLanguage}/home` ? "active" : ""}`}
                    href={`${VITE_PUBLIC_ROOT}/${currentLanguage}/home`}
                    >
                        {t(currentLanguage+'_homemenu')}
                </a>
            </li>
            <li className="nav-item">
                <Link 
                    className={`nav-link ${location.pathname === `/${currentLanguage}/empresa` ? "active" : ""}`}
                    to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/empresa`}
                    >
                        {t(currentLanguage+'_empresa')}
                </Link>
            </li>
            <li className="nav-item">
                <Link 
                    className={`nav-link ${location.pathname === `/${currentLanguage}/contatos` ? "active" : ""}`}
                    to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/contatos`}
                    >
                        {t(currentLanguage+'_contactos')}
                </Link>
            </li>
            <UserBar tokenData={tokenData} />
            {tokenData &&(
                <CartBar />
            )}
            <LanguageBar />
            <li className="nav-item">
                <Link className="btn btn-outline-secondary p-2 btquerocomprar" 
                        to={tokenData ? (`${VITE_PUBLIC_ROOT}/${currentLanguage}/loja`) : (`${VITE_PUBLIC_ROOT}/${currentLanguage}/registo`)}>
                    {t(currentLanguage+'_quero-comprar')}
                </Link>
            </li>
        </ul>
    )
}

export default Navbaropcoes
