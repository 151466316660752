import React, { createContext, useState } from 'react';
import { getTokenData } from '../services/dataStorage.service';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(getTokenData());

  const handleAuthContext = () => {
    setUserData(getTokenData())
  }

  return (
    <AuthContext.Provider value={{ userData, handleAuthContext }}>
      {children}
    </AuthContext.Provider>
  );
};