import React, { createContext, useState } from 'react';

export const ManutContext = createContext();

export const ManutProvider = ({ children }) => {
    const [ manutAuth, setManutAuth] = useState("")

    return (
        <ManutContext.Provider value={{ manutAuth, setManutAuth }}>
            {children}
        </ManutContext.Provider>
    );
};