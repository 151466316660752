import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL
export async function submitRegisterForm(nomeEmpresa, nif, morada, localidade, cpostal, distrito, pais, email, telefone, nomeContacto, telefoneContacto, comentario, alias, language, token){
    try {
        const response = await axios.post(apiUrl+"registerform/", {
            nomeEmpresa: nomeEmpresa,
            nif: nif,
            morada: morada,
            localidade: localidade,
            cpostal: cpostal,
            distrito: distrito,
            pais: pais,
            email: email,
            telefone: telefone,
            nomeContacto: nomeContacto,
            telefoneContacto: telefoneContacto,
            comentario: comentario,
            alias : alias,
            language: language,
            tokencaptcha: token
        })
        return true
    } catch (error) {
        // throw error
        console.log(error.response.data.message, error.response.data.error)
        throw error
    }
}
