import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getFooterPages } from '../data/footer';
import { Base64 } from 'js-base64';
import { Link } from 'react-router-dom';
const Footer = () => {
    const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT
    const date = new Date().getFullYear();
    const [pages, setPages] = useState("")
    const { t } = useTranslation();

    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);

    async function getPages(){
        const response = await getFooterPages(currentLanguage)
        setPages(response)
    }
    
    useEffect(() => {
        getPages()
    }, [])

    return (
        <footer className="bg-dark footer">
            <div className="footer-top py-8">
                <div className="container">
                    <div className="row gy-5 m-footer-15">
                        <div className="col-lg-6 pe-xxl-10">
                            <div className="row gy-5">
                                <div className="col-6 col-lg-6">
                                    <h5 className="text-white footer-title-01">{t(currentLanguage+'_morada')}</h5>
                                    <p className='text-white display-4 caixatexto'>
                                        {pages && pages.length > 0 && pages.map((page) => {
                                            if(page.pageSeoSlug === "rodape_morada"){
                                                return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                            }
                                        })}
                                    </p>
                                    <h5 className="text-white footer-title-01">{t(currentLanguage+'_contactos')}</h5>
                                    <p className='text-white display-4 caixatexto'>
                                        {pages && pages.length > 0 && pages.map((page) => {
                                            if(page.pageSeoSlug === "rodape_contatos"){
                                                return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                            }
                                        })}
                                    </p>
                                </div>
                                <div className="col-6 col-lg-6">
                                    <h5 className="text-white footer-title-01">{t(currentLanguage+'_horario')}</h5>
                                    <p className='text-white caixatexto'>
                                        {pages && pages.length > 0 && pages.map((page) => {
                                            if(page.pageSeoSlug === "rodape_horario"){
                                                return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                            }
                                        })}
                                    </p>
                                </div>                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5 className="text-white footer-title-01 fs-5">{t(currentLanguage+'_saiba-onde-estamos')}</h5>                            
                                {pages && pages.length > 0 && pages.map((page) => {
                                    if(page.pageSeoSlug === "rodape_mapa"){
                                        return <div className="google-map" key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                    }
                                })}                       
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom small py-3 border-top border-white border-opacity-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start py-1">
                            <p className="m-0 text-white text-opacity-75">© {date} copyright by <a className="text-reset" href="https://www.cd2s.pt" target='parent'>cd2s.pt</a></p>
                        </div>
                        <div className="col-md-6 text-center text-md-end py-1">
                            <ul className="nav justify-content-md-end list-unstyled footer-link-01 m-0">
                                <li className="p-0 mx-3 ms-md-0 me-md-3">
                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/politica-privacidade"} className="text-white text-opacity-75 atalhofooter">
                                        {t(currentLanguage+'_politica-de-privacidade')}
                                    </Link>
                                </li>
                                <li className="p-0 mx-3 ms-md-0 me-md-3">
                                    <a href='https://www.livroreclamacoes.pt/Pedido/Reclamacao' target='_blank' className="text-white text-opacity-75 atalhofooter">
                                        {t(currentLanguage+'_livro-de-reclamacoes')}
                                    </a></li>
                                <li className="p-0 mx-3 ms-md-0 me-md-3">
                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/leitigios"} className="text-white text-opacity-75 atalhofooter">
                                        {t(currentLanguage+'_centros-de-arbitragem-de-litigios')}
                                    </Link>
                                </li>
                                <li className="p-0 mx-3 ms-md-0 me-md-3">
                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/como-comprar"} className="text-white text-opacity-75 atalhofooter">
                                        {t(currentLanguage+'_como-comprar')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer