import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL

export async function getFooterPages(language){
    try {
        const response = await axios.get(apiUrl+"pages/rodape/"+language+"/")
        return response.data.pages
    } catch (error) {
        console.log(error.response.data.message)
    }
}

